export default {
  form: {
    title: {
      line_1: "Zamów ofertę!",
      line_2: "Zostaw kontakt"
    },
    order: "Wyślij",
    agreements: {
      phone: {
        small: "Zgoda na kontakt telefoniczny",
        large: "Wyrażam zgodę na przekazywanie przez VEHIS Sp. z o.o. informacji handlowych dotyczących produktów i usług telefonicznie lub za pośrednictwem SMS/MMS, w szczególności celem realizacji niniejszego zgłoszenia. Przyjmuję do wiadomości, że powyższa zgoda może zostać przeze mnie cofnięta w dowolnym momencie. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania danych, których przetwarzanie jest oparte na przepisach prawa.",
        more: "[więcej]",
        less: "[mniej]"
      },
      nip: {
        small: "Akceptuję regulamin programu partnerskiego",
        large: "Oświadczam, że zapoznałem się z treścią i akceptuję regulamin określający zasady współpracy w zakresie kierowania do VEHIS potencjalnych klientów. Treść regulaminu",
        more: "[więcej]",
        less: "[mniej]"
      },
      mail: {
        small: "Zgoda na kontakt elektroniczny",
        large: "Wyrażam zgodę na przesyłanie przez VEHIS Sp. z o.o. informacji handlowych dotyczących produktów i usług na podany przeze mnie w formularzu kontaktowym adres e-mail, w tym w szczególności celem realizacji niniejszego zgłoszenia. Przyjmuję do wiadomości, że zgoda może zostać cofnięta w dowolnym momencie. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania danych, których przetwarzanie jest oparte na przepisach prawa.",
        more: "[więcej]",
        less: "[mniej]"
      }
    },
    disclaimer: {
      content: "Wysyłając formularz wyrażasz zgodę na przetwarzanie danych osobowych wg",
      more: "[tych zasad]"
    },
    success: {
      title: "Sukces!",
      message: "Dziękujemy za przesłany formularz! Skontaktujemy się z Tobą niebawem!"
    },
    failure: {
      title: "Uuups! Coś poszło nie tak!",
      message: "Niestety Twój formularz nie został wysłany. Sprawdź poprawność danych i spróbuj ponownie."
    }
  },
  leasingFeatures: {
    types: [
      "Leasing VEHIS",
      "Klasyczny leasing",
      "Najem długoterminowy",
      "Kredyt samochodowy"
    ],
    labels: [
      "Wymiana lub zwrot samochodu w każdej chwili",
      "Możliwość wykupu samochodu po ustalonej z góry cenie",
      "Możliwość zwrotu samochodu na zakończenie umowy",
      "Brak limitu kilometrów",
      "Niska wpłata własna",
      "Koszt ubezpieczenia w racie",
      "Wsparcie serwisowe",
      "Możliwość przedłużenia umowy",
      "Brak dopłat za ponadnormatywne zużycie"
    ],
    texts: [
      "To oferta, która łączy w sobie najlepsze cechy klasycznych form finansowania samochodu. Do głównych przewag leasingu VEHIS należy możliwość wymiany lub zwrotu samochodu nawet przed końcem umowy oraz program wsparcia serwisowego.",
      "Leasing jest preferowaną przez przedsiębiorców formą finansowania samochodu, ale w swojej klasycznej formie ma wiele ograniczeń. Największym z nich jest brak możliwości przedterminowego zakończenia umowy. Nawet jeśli zmienią się Twoje potrzeby odnośnie samochodu, w klasycznym leasingu nie będziesz mógł w prosty i szybki sposób dokonać wymiany czy zwrotu.",
      "Mimo kilku zalet, najem długoterminowy ma dwa bardzo istotne obostrzenia: określony limit kilometrów oraz konieczność dopłaty za ponadnormatywne zużycie samochodu. Przekroczenie limitu wiąże się z dokonaniem dopłat za każdy kilometr ponad limit. Dopłaty za ponadnormatywne zużycie są uiszczane po zwrocie pojazdu, więc trudno je skalkulować na starcie, a mogą istotnie zawyżyć koszt całego finansowania.",
      "Kredyt samochodowy to popularna forma finansowania, w której samochód pozostaje Twoją własnością. Twoja rata obliczona jest jednak od 100% wartości samochodu, przez co miesięczne zobowiązanie jest dużo wyższe niż w leasingu. Kredyt nie zakłada też możliwości zwrotu samochodu na zakończenie umowy, a jego przyznanie jest bardziej skomplikowane."
    ]
  },
  digital: {
    intro: "Udzielam pełnomocnictwa i wyrażam zgodę na przekazanie przez VEHIS do BIK:",
    agreements: [
      "stanowiących tajemnicę bankową dotyczących w/w osób jako przedsiębiorców, a także dotyczących w/w osób jako osoby fizyczne – konsumentów, przetwarzanych w BIK dla oceny zdolności leasingowej/pożyczkowej i analizy ryzyka leasingowego/pożyczkowego oraz przetwarzanie w tym celu przez BIK ich danych osobowych przekazanych przez VEHIS w zapytaniu, przez okres nie dłuższy niż 2 lata, w tym ich udostępnianie bankom, instytucjom ustawowo upoważnionym do udzielania kredytów, instytucjom kredytowym oraz innym podmiotom na podstawie udzielonej im przez w/w osoby zgody.",
      "informacji dotyczących zobowiązań w/w osób wynikających z zawartej z VEHIS umowy oraz przetwarzanie tych informacji przez BIK do dnia odwołania zgody, nie dłużej jednak niż 5 lat po wygaśnięciu zobowiązania w celu oceny zdolności leasingowej/pożyczkowej i analizy ryzyka leasingowego/pożyczkowego, w tym ich udostępnianie bankom, instytucjom ustawowo upoważnionym do udzielania kredytów, instytucjom kredytowym oraz innym podmiotom na podstawie udzielonej im przeze w/w osoby zgody. Niniejsza zgoda obejmuje również udostępnianie VEHIS przez BIK, informacji, w tym stanowiących tajemnice bankową dotyczących prowadzonej przeze nie działalności gospodarczej przetwarzanych w BIK dla oceny zdolności leasingowej/pożyczkowej i analizy ryzyka leasingowego/pożyczkowego w trakcie obowiązywania zawartej z VEHIS umowy.",
      "danych osobowych w/w osób, oraz przetwarzanie tych danych przez BIK do dnia odwołania zgody, w celu oceny zdolności leasingowej/pożyczkowej i analizy ryzyka leasingowego/pożyczkowego, w tym ich udostępnianie bankom, instytucjom ustawowo upoważnionym do udzielania kredytów, instytucjom kredytowym oraz innym podmiotom na podstawie udzielonej im przeze w/w osoby zgody.",
    ],
    moreover: "Ponadto:",
    declare: "Oświadczam,",
    statements: [
      "że w/w osoby upoważniły VEHIS do wystąpienia do biur informacji gospodarczej (w tym w szczególności Krajowego Rejestru Długów), w tym za pośrednictwem BIK o ujawnienie informacji gospodarczych dotyczących ich zobowiązań, w tym dotyczących ich jako konsumentów",
      "że w/w osoby zostały poinformowane o prawie dostępu do swoich danych osobowych, ich poprawiania oraz dobrowolności udzielenia zgody na przetwarzanie danych jak również o możliwości odwołania w każdym czasie zgody, złożonej w ich imieniu przez VEHIS.",
      "że w/w osoby zostały poinformowane o prawie odwołania powyższych zgód w każdym czasie oraz przyjęły do wiadomości, że odwołanie zgody nie wpływa na zgodność z prawem przetwarzania danych przed wycofaniem zgody."
    ]
  },
  messages: {
    badEmail: "Wprowadź poprawny adres email",
    badPhone: "Wprowadź poprawny numer telefonu",
    allAgreementsRequired: "Wszystkie zgody są wymagane"
  },
  payment: {
    form: {
      title: "Rezerwacja samochodu",
      agreement: {
        title: "Akceptuję [regulamin] rezerwacji samochodu",
        content: "Wpłacając kwotę kaucji w wysokości 1 000 zł zgadzasz się na zawarcie umowy kaucji rezerwacyjnej na  warunkach wynikających z Regulaminu kaucji rezerwacyjnej [(Regulamin kaucji rezerwacyjnej)]. W ramach tej umowy VEHIS sp. z o.o. oraz Finanse sp. z o.o. zarezerwują samochód na poczet przyszłej umowy o finansowanie i świadczenie pozostałych usług dot. pojazdu, której zawarcie zależy od pozytywnej decyzji leasingowej, na okres do 48 godzin upływających w dni robocze. Kaucja jest nieoprocentowana. W razie nie zawarcia umowy o finansowanie, kaucja zostanie zwrócona w całości. Z tytułu rezerwacji samochodu nie poniesiesz żadnych opłat (poza wpłatą kwoty kaucji).",
        more: "[więcej]",
        less: "[mniej]"
      },
    }
  }
}
