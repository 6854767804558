<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'text'
  },
  label: String,
  name: {
    type: String,
    default: ""
  },
  mask: {
    type: Array,
    default: []
  },
  readonly: {
    type: Boolean,
    default: () => false
  },
  disableTab: {
    type: Boolean,
    default: () => false
  },
  customLabelColor: {
    type: String,
    default: () => 'text-gray-500'
  },
  modelValue: [String,Number],
  error: [String,Boolean]
})

const emits = defineEmits(['update:modelValue', 'focus', 'input', 'blur']);
const onInput = ($event) => {
  emits('update:modelValue', $event.target.value);
  emits('input', $event);
}
const onBlur = ($event) => {
  emits('blur', $event.target.value);
}
</script>

<template>
  <div class="relative w-full h-12 pt-3 border-b border-b-gray-500" :class="{'!border-b-error-red': error}">
    <input 
      :readonly="readonly"
      v-if="!props.mask.length > 0"
      :type="props.type"
      :name="props.name"
      class="peer w-full appearance-text h-9 text-sm font-light focus:outline-none rounded-none bg-transparent placeholder-transparent pt-0.5"
      :placeholder="props.label"
      :value="modelValue"
      @input="onInput($event)"
      @focus="emits('focus')"
      @blur="onBlur($event)"
      :tabindex="props.disableTab ? '-1' : undefined"
    />

    <input v-else           
    :type="props.type"
      :name="props.name"
      class="peer w-full appearance-text h-9 text-sm font-light focus:outline-none rounded-none bg-transparent placeholder-transparent text-gray-600 pt-0.5"
      :placeholder="props.label"
      :value="modelValue"
      @input="onInput($event)"
      @focus="emits('focus')"
      @blur="onBlur($event)"
      v-mask="[props.mask[0], props.mask[1]]">

    <div v-show="error" class="absolute text-[10px] left-0 top-0.5 h-3 text-error-red font-extralight !leading-none">{{ error }}</div>
    <label class="absolute h-3 font-extralight !leading-none transition-all pointer-events-none
                  text-[10px] left-0 top-0.5
                  peer-placeholder-shown:text-sm peer-placeholder-shown:top-6 peer-placeholder-shown:opacity-100
                  peer-focus:text-[10px] peer-focus:top-0.5"
          :class="{'peer-focus:opacity-0 opacity-0':!!error,customLabelColor: customLabelColor  }"
    >
      {{ label }}
    </label>
  </div>
</template>
