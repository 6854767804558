<script setup>
const props = defineProps({
  customSuccessTxt: {
    type: String,
    default: () => 'Dziękujemy za zainteresowanie naszą ofertą. Skontaktujemy się z Tobą niebawem.'
  }
})
let currentState = "";

const state = ref("");
const animateState = ref([]);
const visibleState = ref([]);

const animateOut = () => {
  return new Promise(resolve => {
    if (currentState === "") {
      resolve();
      return;
    }
    animateState.value[currentState] = false;
    setTimeout(() => {
      visibleState.value[currentState] = false;
      resolve();
    }, 200);
  })
}

const animateIn = () => {
  return new Promise(resolve => {
    visibleState.value[currentState] = true;
    setTimeout(() => {
      animateState.value[currentState] = true;
      resolve();
    }, 50);
  })
}

const setBusy = () => state.value = 'busy';
const setFailure = () => state.value = 'failure';
const setSuccess = () => state.value = 'success';
const setNone = () => state.value = '';

defineExpose({
  setBusy,
  setFailure,
  setSuccess,
  setNone
})

watch(() => state.value,
  async (newState) => {
    if (currentState === newState) return;
    await animateOut();
    currentState = newState;
    animateIn();
  }
)


</script>


<template>
  <div class="hidden absolute rounded inset-0 bg-white/80 z-20 opacity-0 justify-center items-center transition-opacity overflow-hidden"
       :class="{'!flex': visibleState['busy'], 'opacity-100': animateState['busy']}">
    <div class="flex items-center">
      <EffectLoader class="self-center" />
    </div>
  </div>

  <div class="hidden absolute rounded inset-0 bg-gray-300/80 z-20 opacity-0 justify-center items-center transition-opacity"
       :class="{'!flex': visibleState['success'], 'opacity-100': animateState['success']}">

    <div class="bg-white py-6 px-6 flex flex-col justify-center items-center gap-6 w-full">
      <div class="text-celadon font-light text-xl">Sukces!</div>
      <div class="font-extralight text-center" v-html="props.customSuccessTxt"></div>
      <ButtonRounded color="transparent" class="w-full !border-celadon !text-celadon max-w-64" @click="setNone">OK</ButtonRounded>
    </div>
  </div>

  <div class="hidden absolute rounded inset-0 bg-gray-300/80 z-20 opacity-0 justify-center items-center transition-opacity"
       :class="{'!flex': visibleState['failure'], 'opacity-100': animateState['failure']}">
    <div class="bg-white py-6 px-6 flex flex-col justify-center items-center gap-6 w-full">
      <div class="text-error-red font-light text-xl">Błąd!</div>
      <div class="font-extralight text-center">Formularz nie został wysłany poprawnie. Prosimy, spróbuj jeszcze raz.</div>
      <ButtonRounded color="transparent" class="w-full !border-error-red !text-error-red max-w-64" @click="setNone">OK</ButtonRounded>
    </div>
  </div>
</template>
